.accordion-container {
    border: 1px solid #e7eaee;
    border-radius: 9px;
    padding: 15px 20px;
    width: 100%;
    min-height: 57px;
    margin-block: 15px;

    &:not(.disabled):not(:has(input:checked)):hover {
        background-color: #fbfbfb;
        cursor: pointer;
    }

    &.disabled {
        cursor: not-allowed !important;
        opacity: 0.6;
        user-select: none;

        .accordion-header label {
            cursor: not-allowed !important;
        }
    }
}

.accordion-header {
    input {
        width: 0;
        height: 0;
        display: none;

        &:checked + label::after {
            transform: rotate(-180deg);
        }
    }

    label {
        display: flex;
        font-size: 16px;
        align-items: center;
        column-gap: 10px;
        line-height: 1.4;
        color: #4b5768;
        cursor: pointer;
        user-select: none;

        &::after {
            flex-shrink: 0;
            width: 16px;
            height: 24px;
            margin-left: auto;
            transform: rotate(0);
            content: "";
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.4714 10.9714C8.21105 11.2318 7.78894 11.2318 7.52859 10.9714L4.19526 7.63809C3.93491 7.37774 3.93491 6.95563 4.19526 6.69528C4.45561 6.43493 4.87772 6.43493 5.13807 6.69528L8 9.55721L10.8619 6.69528C11.1223 6.43493 11.5444 6.43493 11.8047 6.69528C12.0651 6.95563 12.0651 7.37774 11.8047 7.63809L8.4714 10.9714Z" fill="%234B5768"/></svg>');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: left center;
            transition: transform 0.2s ease-in-out;
        }
    }
}

.accordion-main {
    display: none;
}

.accordion-header:has(input:checked) + .accordion-main {
    display: block;
    margin-top: 20px;
}
