.primary-button {
    border-radius: 10px;
    padding: 10px 15px;
    color: #191818;
    white-space: nowrap;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.25;
    cursor: pointer;
    background: #f5f5f5;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    transition: opacity 0.2s ease;

    &:hover:not(:disabled) {
        opacity: 0.7;
    }

    &:disabled {
        cursor: pointer;
    }
}
