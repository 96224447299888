.flex {
    display: flex;
}
.none {
    display: none;
}
.flex-column {
    display: flex;
    flex-direction: column;
}
.flex-between {
    display: flex;
    justify-content: space-between;
}
.no-scroll {
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.animated {
    transition: all 0.2s ease;
}

.light-scroll {
    overflow-x: auto;
    &::-webkit-scrollbar {
        width: 5px; /* ширина scrollbar */
        height: 5px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; /* цвет дорожки */
    }
    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1; /* цвет плашки */
        border-radius: 3px; /* закругления плашки */
        /* border: 3px solid orange; padding вокруг плашки */
    }
}

.editable-table__th {
    padding: 8px !important;
    word-break: break-all;

    button {
        font-weight: bold;
    }
}

$red: #ed1a3b;

.column_resize_table th::before {content: ''; display: block; width: var(--column_resize_before_width);}