.link {
    color: #bdbdbd !important;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.7;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 24px;
    text-decoration: none;
    border: none;

    &:hover {
        opacity: 1;
    }

    &.active {
        color: black !important;
        cursor: default;
        opacity: 1;
    }
}
