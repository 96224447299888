.container {
    display: flex;
    flex-direction: column;
    row-gap: 17px;
    padding: 30px;
    border: 1px solid #e4e4e7;
    border-radius: 20px;
}

.template-title {
    font-size: 28px;
}

.tab-title {
    margin: 0;
    font-size: 20px;
}

.template-title,
.tab-title {
    color: #191818;
    line-height: 1.2;
    font-weight: 600;
}

.tab-row {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 12px;
}