.period {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.period-mappings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    width: 100%;
}

.period-mappings .line {
    flex: 1 1 auto;
    height: 2px;
    background-color: #ED1A3B;
}