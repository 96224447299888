.slider {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
}

.arrow {
    border: none;
    background-color: white;
    cursor: pointer;
    padding: 0;

    &.deadlock {
        opacity: 0.3;
        cursor: default;
    }
}

.slider.dark .arrow {
    background-color: #23272f;
    color: #f6f7f9;
  }