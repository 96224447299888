.action-row {
    width: 75px;
    height: 32px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-left: auto;
    
}

.first {
    animation: first 0.5s ease-in-out 1;
}

.second {
    animation: first 0.5s ease-in-out 1;
    animation-delay: 0.5s;
}

@keyframes first {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}
