.hurricane {
    &__checkbox {
        display: none;

        &:checked + .hurricane__label {
            border-color: var(--lukoil-red);
            box-shadow: 0 0 0 2px #f9d4d7;

            &:hover {
                box-shadow: 0 0 0 2px #f4f4f4 !important;
            }
        }
    }

    &__label {
        border-radius: 10px;
        padding: 10px 15px;
        color: #191818;
        // color: white;
        white-space: nowrap;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;
        cursor: pointer;
        // background: #f5f5f5;
        background: var(--indigo-200);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        column-gap: 6px;
        transition: all 0.25s ease;
        border: 2px solid transparent;
        user-select: none;

        &:hover {
            box-shadow: 0 0 0 2px #f9d4d7;
            opacity: 0.7;
            border-color: var(--lukoil-red);
        }
    }
}
