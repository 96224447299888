.route-tab {
    color: #191818;
    background: #f5f5f5;
    border-radius: 10px;
    display: inline-flex;
    flex-direction: column;
    font-weight: 600;
    column-gap: 5px;
    transition: opacity 0.2s ease;
    cursor: pointer;
    border: 2px solid transparent;
    width: 180px;
    min-width: 180px;

    &:not(:last-child) {
        margin-right: 10px;
    }

    > * {
        color: #191818;
        width: 100%;

        &:hover {
            color: #191818;
        }
    }

    &.active {
        border: 2px solid #e22739;
    }

    &:hover {
        opacity: 0.7;
    }
}

.route__link {
    padding: 5px 8px;
    display: inline-flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
}
