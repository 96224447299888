.footer {
    text-align: end;
    background: transparent;
    margin-top: 12px;
    padding: 0;
    border-top: none;
    border-radius: 0;

    button {
        font-size: 16px;
        line-height: 1.5;
        height: 32px;
        padding: 3px 15px;
        border-radius: 6px;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;
    }
}
