.module {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    padding: 30px 40px;
    border: 1px solid #dddddd;
    background-color: white;
    font-family: "Inter" !important;
    font-weight: 600;
    border-radius: 10px;

    * {
        font-family: inherit !important;
    }

    &:hover {
        background-color: #fcfcfc;
    }

    .module__title {
        font-size: 32px;
        color: #ed1a3b;
    }

    .module__link {
        cursor: pointer;
        color: #5f5e5e;
        font-size: 25px;
        display: inline-block;
        padding-bottom: 3px;
        border-bottom: 2px solid #5F5E5E;
        opacity: 1;
        
        &:hover {
            opacity: 0.7;
        }
    }
}
