@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Black.woff') format('woff2'),
		url('../fonts/ProximaNova-Black.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Black.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-SemiBold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Inter';
    src: url('../fonts/Inter-SemiBold.woff') format('woff');
    font-weight: 700;
    font-style: bold;
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-ThinBETA.woff');
	font-weight: 300;
	font-style: thin;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNova-Semibold.woff') format('woff2'),
		url('../fonts/ProximaNova-Semibold.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNovaBold.woff') format('woff2'),
		url('../fonts/ProximaNovaBold.woff') format('woff');
	font-weight: 700;
	font-style: bold;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNovaRegular.woff') format('woff2'),
		url('../fonts/ProximaNovaRegular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'ProximaNova';
	src: url('../fonts/ProximaNovaThin.woff') format('woff2'),
		url('../fonts/ProximaNovaThin.woff') format('woff');
	font-weight: 300;
	font-style: thin;
}