.toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 55px;
    height: 24px;
    display: inline-block;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all ease 0.3s;
}

.toggle:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 2;
    width: 20px;
    height: 20px;
    background: #fff;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    font: 10px/28px Helvetica;
    text-transform: uppercase;
    font-weight: bold;
    text-indent: -22px;
    word-spacing: 37px;
    text-shadow: -1px -1px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.toggle:not(:checked) {
    background-color: #f5f5f5;
    &:hover {
        background-color: #d9d9d9;
    }
}

.toggle:checked {
    background-color: #4cd964;
    color: #4b5768;
    &:hover {
        background-color: #32b232;
    }
}

.toggle:checked:before {
    left: 32px;
}
