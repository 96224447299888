.guide-creating__modal {
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.guide-creating__btn {
    margin: 30px auto 0
}