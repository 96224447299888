.list {
    display: grid;
    gap: 10px;
    grid-template-columns: 5fr 2fr 15fr 1fr;
    align-items: center;
    background-color: #f5f5f5;
    min-height: 60px;
    padding-inline: 12px;

    .list-item {
        font-size: 14px;
        font-weight: 600;
        color: #252b37;
        text-align: center;
        display: inline-flex;
        align-items: center;
        gap: 6px;

        &.clickable {
            cursor: pointer;
        }
    }
}
