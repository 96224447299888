.hurricane-input {
    min-height: 50px;
    .ant-input-affix-wrapper {
        border-color: #d9d9d9;
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px #f4f4f4;
    }

    &:is(:hover, :focus) {
        border-color: #d9d9d9;
        box-shadow: 0 0 0 2px #f4f4f4;
    }

    &:where(.css-dev-only-do-not-override-1rwcdlu).ant-input-outlined:focus, &:where(.css-dev-only-do-not-override-1rwcdlu).ant-input-outlined:focus-within {
        border-color: #d9d9d9;
        box-shadow: 0 0 0 2px #f4f4f4;
    }

    &.lukoil {
        /* .ant-input-affix-wrapper {
            border-color: #e22739;
        } */

        .ant-input-affix-wrapper-focused {
            box-shadow: 0 0 0 2px #f9d4d7;
            border-color: #e22739;
        }

        &:hover {
            box-shadow: 0 0 0 2px #f9d4d7;
            border-color: #e22739;
        }
    }
}
