@import "../../styles/commonStyles.scss";
.event-page-container {
    padding: 0 45px;

    .filter {
        margin: 20px 0;
        width: 100%;
        background-color: white;
        color: #4b5768;
        border: 1px solid lightgray;
        text-align: start;
        padding-inline: 6px;
    }

    .search {
        width: 100%;
    }

    .create-evt-btn {
        min-width: 170px;
        margin-left: 20px;
    }

    .date-picker-container {
        display: flex;
        gap: 20px;
    }

    .date-picker {
        width: 100%;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 10px;
        height: 52px;
        color: rgb(125, 137, 154);
        background-color: rgb(249, 249, 249);
    }

    .ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: #ed1a3b;
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
        background: #ed1a3b;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-start
        .ant-picker-cell-inner {
        background-color: #27c456 !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        background-color: #27c456 !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today
        .ant-picker-cell-inner::before {
        border-color: #1f9643e5 !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
            .ant-picker-cell-range-start-single
        )::before,
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
            .ant-picker-cell-range-end-single
        )::before,
    .ant-picker-time-panel-column
        > li.ant-picker-time-panel-cell-selected
        .ant-picker-time-panel-cell-inner {
        background: #e6ffe8 !important;
    }

    // .ant-btn-primary {
    //     background-color: #27c456 !important;
    //     border-color: #1f9643e5 !important;
    // }

    .custom-select {
        width: 100%;
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 10px;
        height: 52px;
        color: #7d899a;
        background-color: #f9f9f9;

        option {
            border: 1px solid lightgray;
            border-radius: 10px;
            padding: 10px;
            height: 52px !important;
        }
    }
}

.point-events-table {
    width: 100%;
    padding: 10px 0;
    border-collapse: collapse;

    tr {
        border-bottom: 1px solid #e7eaee;
        height: 64px;
    }

    thead {
        text-transform: uppercase;
        color: #4b5768;
        font-size: 14px;
        td {
            width: 15%;
            align-content: center;
        }
    }

    tbody {
        .table-row {
            cursor: pointer;
            &:hover {
                color: lightgray;
            }
            .table-actions {
                display: flex;
                justify-content: center;
                &:hover {
                    color: $red;
                }
            }
            path {
                // stroke: $red !important;
            }
            .chart-container {
                height: 30px;
                min-width: 120px;
                overflow: hidden;
            }
        }
    }
}

//toggle

//checkbox

.checkbox-container:has(> input:disabled:checked)::after {
    width: 10px;
    border-radius: 50px;
    height: 10px;
    font-size: 9px;
    background: #4cd964;
    display: inline-block;
    color: white;
    position: relative;
    content: "";
    text-align: center;
    float: left;
    padding: 2px;
}

.checkbox-container input:disabled:checked {
    display: none;
}
