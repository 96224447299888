ul {
    list-style-type: none;
    padding-left: 0;
}

.columnList {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.rowList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    width: 100%;
    gap: 12px;
}

.banner {
    width: 100%;
    padding-top: 50%;
    position: relative;
    max-height: 450px;
}

.item {
    width: 100%;
    height: 35px;
}

.banner,
.item {
    background: linear-gradient(-90deg, #f0f0f0 0%, #c0c0c0 50%, #f0f0f0 100%);
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in-out infinite;
}

@keyframes pulse {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: -135% 0%;
    }
}