.radio-variant {
    border: 1px solid #e4e4e7;
    background: #f9f9f9;
    border-radius: 9px;
    transition: all 0.25s ease-in-out;
    display: flex;
    padding: 40px 25px;

    .radio-variant.actve {
        border-color: #ed1a3b;
    }
}



.radio-label {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    * {
        line-height: 1;
    }
    p {
        font-weight: 600;
        font-size: 18px;
        color: #191818;
        margin: 0
    }
    span {
        color: #7D899A;
        font-size: 16px;
        font-weight: 500;
    }
}
