.resource {
    display: inline-flex;
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #34c759;
    font-weight: 600;
    text-align: center;
    user-select: none;
    cursor: pointer;
    /* flex: 1 1 auto;
    max-width: calc(25% - 15px); */
    width: max-content;
    white-space: nowrap;

    &.error {
        color: #e22739;
    }
}
