.template {
    display: grid;
    gap: 10px;
    grid-template-columns: 5fr 2fr 15fr 1fr;
    align-items: center;
    transition: background 0.2s ease;
    border-radius: 5px;

    &.hidden {
        display: none;
    }

    &:hover {
        background: #f0f0f0;
    }

    .template__name {
        &.error {
            color: red;
        }
    }

    .template__title,
    .template__year {
        padding-block: 20px;
        border-bottom: 1px solid #e7eaee;
        font-size: 16px;
        text-align: center;
        display: inline-flex;
        height: 100%;
        font-weight: 500;
    }

    .template__title {
        cursor: pointer;
        padding-inline: 10px 20px;
        background: transparent;
        position: relative;
        align-items: center;

        &.alone {
            padding-left: 40px;
        }

        &:not(.alone):before {
            flex-shrink: 0;
            width: 1.25rem;
            height: 1.25rem;
            margin-right: auto;
            content: "";
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="%23212529"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>');
            background-repeat: no-repeat;
            background-size: 1.25rem;
            transition: transform 0.2s ease-in-out;
            margin-right: 10px;
        }

        &.open::before {
            transform: rotate(180deg);
        }
    }

    .template__year {
        align-items: center;
        justify-content: center;
        &.error {
            color: red;
        }
    }
}

.resource-list {
    display: flex;
    align-items: center;
    column-gap: 15px;
    width: 100%;
    overflow-x: auto;
}
