.container-space {
    position: relative;
    margin-top: 6px;
}

.container-space-blocked {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    opacity: 0.6;
    user-select: none;
}

.container-space-blocked.active {
    display: block;
}