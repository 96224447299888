@import "../../styles/commonStyles.scss";



.scroll-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
        color: #7d899a;
    }

    &.paddingable {
        padding: 20px;
    }
}

.custom-select {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    height: 52px;
    color: #7d899a;
    background-color: #f9f9f9;

    option {
        border: 1px solid lightgray;
        border-radius: 10px;
        padding: 10px;
        height: 52px !important;
    }
}

.pair-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pair {
        display: flex;
        gap: 10px;
        align-items: baseline;

        input {
            width: 110px;
        }

        .datetime {
            width: 100px;
        }

        select {
            width: 132px;
        }
    }

    .red-line {
        width: 100%;
        height: 2px;
        background-color: $red;
        margin: 10px;
    }
}

.modificator-container {
    display: flex;
    align-items: center;
    column-gap: 10px;

    input {
        flex: 1 1 auto;
        min-height: 52px;
    }
}

.period-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .period {
        margin-top: 10px;
    }
}


// @media screen and (max-width: 850px) {
//     .modal-background {
//         .modal-body {
//             width: 100%;
//         }
//     }
// }
