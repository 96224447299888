.tab {
    padding: 10px 15px;
    color: #191818;
    background: #f5f5f5;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    column-gap: 5px;
    transition: opacity 0.2s ease;
    cursor: pointer;
    border: 2px solid transparent;
    white-space: nowrap;
    min-width: 250px;

    &:hover {
        opacity: 0.7;
    }

    &.active {
        border: 2px solid #e22739;

        &.editMode {
            color: #e22739;
        }
    }

    &.new {
        background: #e22739;
        color: #fff;
    }

    .tab__icon {
        padding: 5px;
        cursor: pointer;
    }

    span {
        position: relative;
        display: inline-block;
    }

    &.editing span::before {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #ffcc00;
    }
}
