.container {
    display: inline-block;
    height: 24px;

    &.active {
        animation: loader 1.5s linear infinite;
    }
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
