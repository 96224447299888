.bottom-table {
    display: flex;
    flex-direction: column;
    border: 1px solid gray;

    &.hidden {
        /* max-height: 0; */
        overflow: hidden;
        height: 0;
    }

    .row {
        width: 100%;
        &:not(:last-child) {
            border-bottom: 1px solid gray;
        }
    }
    .cell {
        padding: 8px;
        font-size: inherit;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        &:not(:last-child) {
            border-right: 1px solid gray;
            line-height: 1;
        }
    }
}

.bottom-table__key {
    font-weight: 600;
    flex: 0 0 50%;
}

.bottom-table__value {
    justify-content: center;
}