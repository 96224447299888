.none {
  display: none;
}

.modal {
  &-background {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  &-body {
    width: 50%;
    height: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
  }

  &__button {
    width: calc(100% - 40px);
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0 0 20px 20px;
    }
}

.scroll-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
