.tabs {
    display: flex;
    background: #f8f8f8;
    border: 1px solid #e4e4e7;
    padding: 3px;
    border-radius: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow-x: hidden;
    flex: 0 0 auto;
    align-items: center;
    min-height: 46px;
}

.tab__list {
    display: flex;
    align-items: center;
    overflow-x: auto;
    flex: 1 1 auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: transparent;
    }
}

.tab__item {
    display: inline-block;
    margin: 0;
    transition: background 0.4s ease;
    border-radius: 13px;

    &:hover:not(.checked) {
        background: #cdcdce;
    }

    &.checked {
        background: white;
        border-radius: 13px;
        border: 2px solid #97a3ba;
    }
}

.tab__radio {
    display: none;
    overflow: hidden;
}

.tab__label {
    border-radius: 4px;
    font-weight: 600;
    padding: 6px 10px;
    transition: background 0.3s, box-shadow 0.5s ease;
    cursor: pointer;
    border-radius: 13px;
    min-height: 34px;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #272727;
    text-align: center;
    font-size: 16px;
}

.tab__left,
.tab__right {
    flex: 0 0 20px;
    background: transparent;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:not(.deadlock):hover {
        opacity: 1;
    }

    &.deadlock {
        opacity: 0.3;
        cursor: default;
    }
}

.tab__left {
    margin-right: 5px;
}

.tab__right {
    margin-left: 5px;
}
