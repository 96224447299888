.settings-icon {
    flex: 0 0 36px;
    height: 36px;
}

.title {
    margin: 0 !important;
    font-weight: 600 !important;
    color: #191818 !important;
    font-size: 24px !important;
    line-height: 1.2 !important;
}

.subtitle {
    display: flex;
    justify-content: space-between;
    min-height: 32px;
    gap: 20px;
    
    .subtitle__text {
        margin: 0;
        font-size: 18px;
    }
}

.sub-button {
    padding: 10px 25px;
}

.title-container {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1 1 auto;
}

.editing-container {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}