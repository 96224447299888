.breadcrumbs {
    display: flex;
    align-items: center;
    min-height: 28px;
    column-gap: 10px;
}

.settings-icon {
    flex: 0 0 28px;
    height: 28px;
}