.header-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 5px;
    margin: 0 auto;
}

.required {
    position: relative;

    &::after {
        content: '*';
        color: red;
        position: absolute;
        font-size: 16px;
        font-weight: bold;
        right: -7px;
        top: 0;
    }
}