.red-button {
    background-color: #ed1a3b;
    border-radius: 10px;
    border: none;
    color: white;
    height: 50px;
    max-width: 100%;
    cursor: pointer;
    font-weight: 500;
    padding-inline: 12px;
    min-width: 180px;
    transition: all 0.25s ease;

    &:disabled {
        cursor: default;
        background-color: #ffcdd2;
    }
    &:hover:not(:disabled) {
        background-color: #ff1744;
    }

    &:active {
        background-color: #f44336 !important;
    }

    &.gray {
        background: #c8ceda;
        color: #fcfcfd;

        &:disabled {
            background-color: #e0e4eb;
        }
        &:hover:not(:disabled) {
            background-color: #afb8ca;
        }
        &:active {
            background-color: #97a3ba !important;
        }
    }
}
