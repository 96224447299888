.none {
    display: none;
}

.modal-background {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.modal-body {
    width: 50%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
}

.scroll-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}
