@import '../../styles/commonStyles.scss';
.login-container {
    justify-content: space-between;
    overflow: hidden;

    .half-container {
        width: 50%;
        height: 100vh;

        .form-container {
            margin: 30% 10% 0 10%;

            input {
                margin: 10px 0;
            }

            button {
                margin: 10px 0;
            }

            p {
                color: #7D899A;
            }

        }
    }

    .red {
        background-color: $red;
    }

    .logo-wrapper {
        margin-top: 17px;
        margin-left: 10%;
        display: flex;
    }
}