.red-container{
    width: 100%;
    align-items: center;
    display: flex;
    margin: 10px;
    
    .red-circle {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        border: 2px solid #ed1a3b;

    }

    .red-line {
        flex: 1 1 auto;
        height: 2px;
        background-color: #ed1a3b;
    }
}
