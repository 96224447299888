.input {
    position: absolute;
    top: 3px;
    bottom: 3px;
    background: transparent;
    border: none;
    color: black;
    height: auto;
    width: 95%;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 11;
}

.excel-column-item {
    border: 1px solid transparent;

    &:not(:first-child) {
        border-left: 1px solid;
    }

    &.numeric::after {
        position: relative;
        font-size: 12px;
        font-weight: 500;
        pointer-events: none;
        content: "№";
        top: 3px;
        right: 3px;
    }
}

.excel-name-column {
    text-align: start;
    position: sticky;
    z-index: 2;
    left: 0;
    min-height: 40px;
    cursor: grab;
    width: 100%;
    /* left: -18px; */

    .name-container {
        padding: 10px 8px;
        border: 1px solid transparent;

    }
}

:where(.excel-column-item, .excel-name-column > .name-container) {
    &.active {
        cursor: text;

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 2px solid #5292f7;
            z-index: 10;
        }
    }

    &.edited {
        background-color: #ffcc00;
    }

    &.pickedRecently {
        border-color: #217346 !important;
    }
}



.excel-translated-span {
    display: inline-block;
    padding-right: 45px;
    word-wrap: break-word;
    margin: 0;
}

.excel-expanded-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border: 1px solid black;
    background: #a5a5a5;
    cursor: pointer;
    border-radius: 0;
    right: 20px;
    color: black;
    font-weight: 500;
    z-index: 19;

    &:hover {
        background-color: #dcdcdc !important;
        /* opacity: 0.6; */
    }
}

.excel-row {
    position: relative;
    font-weight: 500;

    &:not(:last-child) .excel-name-column {
        border-bottom: 1px solid;
    }

    &.row-active {
        background: orange;
    }
}

.none {
    display: none;
}

.bold {
    font-weight: 600;
}

.hidden {
    height: 0;
    max-height: 0;
    min-height: 0;
    padding: 0;
    min-height: 0;
    overflow: hidden;
}

.uneditable {
    cursor: default;
}

.excel-row.none {
    display: none;
}

.excel__line-depth {
    position: absolute;
    top: -1px;
    bottom: 0;
    z-index: 10;
    width: 2px;
    background: black;
}

.excel__line-start {
    top: 3px;
}

.excel-td {
    position: relative;

    /* &.many {
        &::after {
            content: "";
            position: absolute;
            width: 1px;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: black;
        }
    } */
}

.excel__trigger {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 0.3;
    background-color: #ccc;
    cursor: pointer;

    &:hover {
        background-color: red;
    }

    &.pointed {
        background-color: red;
        opacity: 1;

        &:hover {
            background-color: #ccc;
        }
    }
}

@media print {
    .excel-translated-span {
        display: inline-block;
        padding-right: 0;
        max-width: 100px;
        word-wrap: break-word;
    }

    .excel-expanded-button {
        display: none;
    }
}