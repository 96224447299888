.guide-exist__modal {
    padding: 30px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 40px;
    align-items: center;

    * {
        text-align: center;
    }

    .guide-exist__description {
        font-size: 16px;
    }

    .guide-existing__btn {
        font-size: 18px;
        font-weight: 600;
        padding-inline: 20px;
    }
}
