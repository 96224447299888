.action-button {
    padding: 5px;

    & svg {
        transition: all ease 0.3s;
    }

    &:not(.danger):not(.success):hover svg {
        stroke: #1677ff;
    }

    &.danger:hover svg {
        stroke: red;
    }

    &.success:hover svg {
        stroke: #32b232;
    }
}
