.editable-element {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    min-height: 40px;
    padding: 10px 12px;
    cursor: grab;
    width: 100%;

    &.uneditable {
        cursor: default;
    }

    .editable-elemnt__value {
        display: inline-block;
        width: 100%;
        margin: 0;

        &.none {
            /* display: none; */
            visibility: hidden;
        }
    }

    .editable-element__input {
        position: absolute;
        top: 3px;
        bottom: 3px;
        background: transparent;
        border: none;
        color: black;
        height: auto;
        width: 95%;
        padding: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        z-index: 11;
    }
}

.hidden {
    height: 0;
    max-height: 0;
    min-height: 0;
    padding: 0;
    min-height: 0;
    overflow: hidden;
}