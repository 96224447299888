.search-input {
    position: relative;
    width: 100%;

    &.compacted {
        width: fit-content;
    }

    .search-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        transform: translateY(-50%);
        color: #7d899a;
        cursor: pointer;
        z-index: 4;

        &.search-left {
            left: 19px;
        }
        &.search-right {
            right: 10px;
        }
    }

    .search-item {
        padding-left: 45px;
        height: 52px;
    }

    .ant-input-affix-wrapper {
        border-color: #d9d9d9;
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: 0 0 0 2px #f4f4f4;
    }

    &.lukoil {
        /* .ant-input-affix-wrapper {
            border-color: #e22739;
        } */

        .ant-input-affix-wrapper-focused {
            box-shadow: 0 0 0 2px #f9d4d7;
            border-color: #e22739;
        }
    }
}
